@use "@angular/material" as mat;
// Plus imports for other components in your app.

@include mat.all-component-typographies();
@include mat.core();

// M2 theme I use
$my-primary: mat.m2-define-palette(mat.$m2-cyan-palette);
$my-accent: mat.m2-define-palette(mat.$m2-amber-palette);
$my-warn: mat.m2-define-palette(mat.$m2-red-palette);
$my-theme: mat.m2-define-light-theme(
        (
            color: (
                primary: $my-primary,
                accent: $my-accent,
                warn: $my-warn,
            ),
            density: -1,
        )
);

@include mat.all-component-themes($my-theme);

// Special dense theme for the field app
$my-theme-dense: mat.m2-define-light-theme(
        (
            density: -5,
        )
);
//app-field-measurements {
//    @include mat.all-component-themes($my-theme-dense);
//}
app-field {
    @include mat.all-component-themes($my-theme-dense);
}

///////////////////////////////////////////////////////////////////////
// General stuff
///////////////////////////////////////////////////////////////////////

* {
    --disabled-color: rgba(0, 0, 0, 0.38);
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}


///////////////////////////////////////////////////////////////////////
// Colors
///////////////////////////////////////////////////////////////////////

.error {
    color: mat.get-theme-color($my-theme, warn,) !important;
    //background-color: mat.get-theme-color($my-theme, warn,) !important;
}

.warning {
    background-color: mat.get-theme-color($my-theme, accent,) !important;
}

.active {
    background-color: mat.get-theme-color($my-theme, primary, lighter) !important;
}

.result {
    background-color: lightgreen !important;
}

.debug {
    background-color: mat.get-theme-color($my-theme, accent, lighter);
}

///////////////////////////////////////////////////////////////////////
//  Edited values
///////////////////////////////////////////////////////////////////////

.vide-editable-value.dirty {
    //margin: auto;
    border-width: 2px;
    //border-style: solid;
    border-color: mat.get-theme-color($my-theme, accent);
}

.vide-editable-value {
    margin: auto;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
}

textarea.vide-editable-value {
    //margin: auto;
    border-width: 1px;
    //border-style: solid;
    border-color: darkgray;
}


// use fancy bullet for list
// ul {
//     /* list-style-type: none; */
//     /* list-style-type: "\1F44D"; */
// }

///////////////////////////////////////////////////////////////////////
//  Ng select
///////////////////////////////////////////////////////////////////////
@import "@ng-select/ng-select/themes/default.theme.css";
//@import "@ng-select/ng-select/themes/material.theme.css";
// spell out options and optgroup names, no ellipsis
.ng-dropdown-panel .ng-dropdown-panel-items div {
    .ng-optgroup,
    .ng-option {
        white-space: normal;
    }
}

.ng-select-disabled {
    .ng-select-container {
        .ng-value-container {
            .ng-value,
            .ng-label,
            input {
                color: var(--disabled-color);
            }
        }
    }
}

// An unnecessary scroll bar appears here by default
.mat-drawer-content {
    overflow: inherit !important;
}

///////////////////////////////////////////////////////////////////////
//  Misc stuff
///////////////////////////////////////////////////////////////////////

.vide-disabled {
    color: var(--disabled-color);
}

.vide-columns {
    column-width: 15rem;
}

.vide-columns-wide {
    column-width: 25rem;
}

.vide-form-field .vide-form-field-row {
    margin-top: 8px;
    margin-bottom: 8px;
}

.toolbar-spacer {
    flex: 1 1 auto;
}

ul.no-bullets {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
}

.full-width {
    width: 100%;
}

.many_objects_list {
    /* 	column-width: 200px; */
    max-height: 40em;
    overflow-y: auto;
    /*position: relative;*/
    /* background-color: blueviolet; */
}

//.mat-mdc-row {
//    height: auto;
//}

mat-card {
    //margin: 2px;
}

.padded {
    padding: 16px; // same as mat-card etc.
}

mat-form-field {
    /* background-color: blueviolet; */
    padding: 4px;
}

table {
    /* width: 100%; */
    /* display: block; */
    overflow: auto;
    /* white-space: nowrap; */
}

.constrained-box {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

app-map-plot .js-plotly-plot .plotly .modebar,
app-correlation-map .js-plotly-plot .plotly .modebar {
    /* background-color: brown; */
    // left: 50%;
    right: 100px;
    // left: 2px;
    /* transform: translateX(-50%); */
}

/* Must set this globally */
.dialog-no-padding .mat-mdc-dialog-container {
    padding: 0;
}

// TODO: seems this is better than the default...
.mdc-button > mat-icon.mat-icon {
    margin-right: 0;
}
